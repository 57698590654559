import { Box, Center, Stack, Text, Link } from '@chakra-ui/react';
import { graphql, PageProps, Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { StandardLayout } from '~/components/layout';
import { useSiteMetadata } from '~/hooks/content';

const FourOhFourPage = ({ data }: PageProps<Queries.FourOhFourPageQuery>) => {
  const siteMetadata = useSiteMetadata();

  return (
    <StandardLayout>
      {data.contentfulAsset?.gatsbyImageData && data.contentfulAsset?.title && (
        <Center h="100%">
          <Stack>
            <Box width={{ sm: '100%', lg: '37.5rem' }} mb="2">
              <GatsbyImage
                image={data.contentfulAsset.gatsbyImageData}
                alt={data.contentfulAsset.title}
              />
            </Box>
            <Text textAlign="center">
              This piece has not been composed yet!
            </Text>
            {siteMetadata?.redirectLink404 && (
              <Text textAlign="center">
                <Link
                  as={GatsbyLink}
                  to={siteMetadata.redirectLink404}
                  color="brand.chelseaGem">
                  The ones on this page are.
                </Link>
              </Text>
            )}
          </Stack>
        </Center>
      )}
    </StandardLayout>
  );
};

export default FourOhFourPage;

export const pageQuery = graphql`
  query FourOhFourPage {
    contentfulAsset(
      metadata: { tags: { elemMatch: { contentful_id: { eq: "404" } } } }
    ) {
      title
      gatsbyImageData(placeholder: BLURRED)
    }
  }
`;
